:root {
  --background: #fff;
  --text-primary: midnightblue;
  --text-primary-1: #fff;
  --text-primary-2: midnightblue;
  --text-secondary: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --text-primary: aquamarine;
  --text-primary-1: midnightblue;
  --text-primary-2: aquamarine;
  --text-secondary: midnightblue;
}

html {
  font-family: Montserrat, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-top-highlight-color: #ffffff;
}

*,
:after,
:before {
  box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}

img {
  width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Montserrat, monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  overflow-x: hidden;
  background-color: var(--background);
}

nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  transition: 0.3s ease-in-out;
  background-color: var(--background);
}

nav.navbar a.navbar-brand {
  color: var(--text-primary);
  font-family: sans-serif;
  font-weight: 600;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 0 25px;
  letter-spacing: 0.8px;
  opacity: 0.8;
  color: var(--text-primary-2);
}

nav.navbar .theme-toggle {
  padding-left: 20px;
  cursor: pointer;
}

nav.navbar .theme-toggle i {
  font-size: 24px;
  color: var(--text-primary-2);
}

.home,
.about {
  padding: 250px 0 100px 0;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  nav.navbar a.navbar-brand {
    font-size: 18px;
  }

  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 16px;
    padding: 0 10px;
  }

  .home,
  .about,
  .projects {
    padding: 100px 0 50px 0;
  }
}
